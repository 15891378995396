<template>
	<div class="reset-password col" align-x="center">
		<quote-title label="重設密碼" />
		<br>
		<br>
		<br>
		<el-form ref="ruleForm" :model="ruleForm" size="small">
			<el-form-item label="密碼" prop="password" :rules="rule(/[a-zA-Z0-9]{8,}/, '８碼以上英文字母與半形數字')">
				<el-input v-model="ruleForm.password" placeholder="密碼" show-password class="round" />
			</el-form-item>
			<el-form-item label="請再次輸入密碼" prop="repeat_password" :rules="validatePassword(ruleForm)">
				<el-input v-model="ruleForm.repeat_password" placeholder="請再次輸入密碼" show-password class="round" />
			</el-form-item>
		</el-form>
		<br>
		<div class="center">
			<el-button type="theme" round @click="handleResetPassword()">重設密碼</el-button>
		</div>
		<br>
		<br>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	props: ["token"],
	data() {
		return {
			ruleForm: new Object
		}
	},
	methods: {
		...mapActions("auth", ["_resetPassword"]),
		async handleResetPassword() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					this.$message.success(await this._resetPassword({ token: this.token, ...this.ruleForm }));
					this.$router.push("/login");
				}
			});
		},
		validatePassword({ password, repeat_password }) {	// 驗證重複密碼
			return [
				{ required: true, message: "必填" },
				{
					validator: (rule, value, callback) => {
						if (password === repeat_password) callback();
						else callback(new Error("兩次密碼不一樣"));
					}
				}
			]
		},
		rule(regex, errMsg) {
			return [
				{ required: true, message: "必填" },
				{
					validator: (rule, value, callback) => {
						if (!regex || (regex && regex.test(value))) {
							callback();
						} else {
							callback(new Error(errMsg || "格式錯誤"));
						}
					},
					trigger: "blur"
				}
			];
		},
	}
}
</script>

<style lang="scss" scoped>
.reset-password {
	padding: 50px 5%;
}
.el-form {
	width: 100%;
	max-width: 400px;
}
</style>